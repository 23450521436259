const userRegisterService = http => {
  const endpointPost = `${process.env.VUE_APP_API_BASE_URL}register`
  const endpointActivate = `${process.env.VUE_APP_API_BASE_URL}active-account`

  const post = data => http.post(endpointPost, data)

  const activate = uuid => {
    const url = `${endpointActivate}/${uuid}`
    return http.get(url).then(data => data.data)
  }

  return {
    post,
    activate,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  userRegisterService,
}
