<template>
  <b-card :title="`${$t('users.list_users')}`">
    <b-card-text>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between mb-1">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="`${$t('users.field_search')}`"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              class="mr-1"
              size="sm"
              @click="editarItem(props.row.uuid)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>{{ $t('users.actions.view') }}</span>
            </b-button>
            <b-button
              v-if="props.row.email_verified_at === null"
              size="sm"
              @click="activateUser(props.row.uuid)"
            >
              <feather-icon
                icon="UserCheckIcon"
                class="mr-50"
              />
              <span>{{ $t('users.actions.activate') }}</span>
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'created_at'">
            {{ props.row.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
          </span>

          <span v-else-if="props.column.field === 'email_verified_at'">
            {{ props.row.email_verified_at | moment('DD/MM/YYYY - H:mm:ss') }}
          </span>

          <span v-else-if="props.column.field === 'kyc'">
            {{ statusVariantLabel(props.row.kyc) }}
          </span>

          <div v-else-if="props.column.field === 'usd_value'">

            <span v-if="!props.row.editBalance">
              {{ formatPrice(props.row.usd_value) }}

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                size="sm"
                @click="editBalance(props.row)"
              >
                <feather-icon
                  icon="Edit2Icon"
                />
              </b-button>
            </span>

            <div v-else>
              <validation-observer ref="editBalanceForm">
                <validation-provider
                  #default="{ errors }"
                  name="valor"
                  rules="required|min:1"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="balanceValue"
                      v-model.lazy="props.row.usd_value"
                      v-money="money"
                      name="balanceValue"
                      type="text"
                      min="1"
                      :state="errors.length > 0 ? false : null"
                      placeholder="0,00"
                    />
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="info"
                      size="sm"
                      @click="updateBalance(props.row)"
                    >
                      <feather-icon
                        icon="SaveIcon"
                      />
                    </b-button>
                  </div>

                </validation-provider>
              </validation-observer>
            </div>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-text>

    <b-modal
      id="modalEdit"
      centered
      :title="`${$t('users.modal_info.title')}`"
      hide-footer
    >
      <b-card-text v-if="renderInfo">
        <span>
          {{ $t('users.modal_info.registered_at') }}:
        </span>
        <b-badge variant="dark">
          {{ editItem.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
        </b-badge>
        <b-badge
          :variant="statusVariant(editItem.kyc)"
          class="mx-1"
        >
          {{ statusVariantLabel(editItem.kyc) }}
        </b-badge>
        <p>
          <strong>{{ $t('register.form.name') }}:</strong> {{ editItem.name }}<br>
          <strong>{{ $t('register.form.email') }}:</strong> {{ editItem.email }}<br>
          <strong>{{ $t('register.form.phone') }}:</strong> {{ editItem.phone }}<br>
        </p>

        <div v-if="editItem.documents.length > 0">
          <p>
            <strong>{{ $t('users.modal_info.documents') }}:</strong><br>
            <strong>{{ $t('register.form.document_type') }}:</strong> {{ editItem.documents[0].type }}<br>
            <strong>{{ $t('register.form.document_number') }}</strong> {{ editItem.documents[0].number }}<br>
            <strong>{{ $t('register.form.document_file') }}:</strong>
          </p>
          <a
            :href="editItem.documents[0] !== null ? editItem.documents[0].file : ''"
            target="_blank"
          >{{ $t('users.modal_info.form.view_document') }}</a>
        </div>
        <validation-observer ref="userForm">
          <b-form @submit.prevent>
            <b-form-group>
              <label for="status">{{ $t('users.modal_info.form.alter_status') }}</label>
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-select
                  id="status"
                  v-model="status"
                  name="status"
                  :options="defaultOptions"
                  :state="errors.length > 0 ? false : null"
                  class="mb-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              class="mt-1"
              @click="validationForm"
            >
              {{ $t('users.modal_info.form.button_submit') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BCard,
  BCardText,
  BButton,
  BModal,
  BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { userRegisterService } from '@/services/user/register'
import { userService } from '@/services/user/list'
import { userUpdateService } from '@/services/user/update'

import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BBadge,
    BModal,
  },
  directives: {
    Ripple,
    mask,
    money: VMoney,
  },
  data() {
    return {
      required,
      userService: userService(this.$http),
      userUpdateService: userUpdateService(this.$http),
      userRegisterService: userRegisterService(this.$http),
      dir: false,
      status: '',
      rows: [],
      searchTerm: '',
      editItem: {},
      newItem: {},
      renderInfo: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('users.table_header.name'),
          field: 'name',
        },
        {
          label: this.$t('users.table_header.email'),
          field: 'email',
          sortable: false,
        },
        {
          label: this.$t('users.table_header.phone'),
          field: 'phone',
          sortable: false,
        },
        {
          label: this.$t('users.table_header.status'),
          field: 'kyc',
        },
        {
          label: this.$t('users.table_header.balance'),
          field: 'usd_value',
        },
        {
          label: this.$t('users.table_header.created_at'),
          field: 'created_at',
        },
        {
          label: this.$t('users.table_header.verified_at'),
          field: 'email_verified_at',
        },
        {
          label: this.$t('users.table_header.actions'),
          field: 'action',
          sortable: false,
        },
      ]
    },
    defaultOptions() {
      return [
        { value: 1, text: this.$t('users.dropdown_status.pending_approval') },
        { value: 2, text: this.$t('users.dropdown_status.disapproved') },
        { value: 3, text: this.$t('users.dropdown_status.approved') },
      ]
    },
    statusVariantLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        1: this.$t('users.dropdown_status.pending_approval'),
        2: this.$t('users.dropdown_status.disapproved'),
        3: this.$t('users.dropdown_status.approved'),
        /* eslint-enable key-spacing */
      }

      return status => statusLabel[status]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        3: 'light-success',
        2: 'light-danger',
        1: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.listItens()
  },
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async listItens() {
      const resultado = await this.userService.getAdmin('page=1')

      // eslint-disable-next-line arrow-body-style
      this.rows = resultado.data.map(item => {
        return {
          ...item,
          editBalance: false,
        }
      })
    },
    async editarItem(id) {
      this.renderInfo = false
      const resultado = await this.userService.getUser(`uuid=${id}`)
      this.editItem = resultado
      this.renderInfo = true
      this.$bvModal.show('modalEdit')
    },
    async activateUser(id) {
      try {
        await this.userRegisterService.activate(id)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuário ativado com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        await this.listItens()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao ativar usuário.',
            text: 'Algo deu errado!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    editBalance(item) {
      // eslint-disable-next-line no-param-reassign
      item.editBalance = true
    },
    updateBalance(item) {
      this.$refs.editBalanceForm.validate().then(async success => {
        if (success) {
          try {
            const updateValue = item.usd_value
              .replace(/\.*/g, '')
              .replace(',', '.')

            const data = await {
              user_uuid: item.uuid,
              usd_value: updateValue,
            }

            await this.userUpdateService.update(data)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Saldo atualizado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.listItens()

            // eslint-disable-next-line no-param-reassign
            item.editBalance = false
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro atualizar o saldo.',
                text: 'Algo deu errado!',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.userForm.validate().then(async success => {
        if (success) {
          try {
            const data = await {
              user_uuid: this.editItem.uuid,
              kyc: this.status,
            }

            await this.userUpdateService.update(data)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário atualizado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$bvModal.hide('modalEdit')
            this.listItens()
            this.status = ''
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao enviar atualizar.',
                text: 'Algo deu errado!',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
  },
}
</script>
